import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Anchor,
  Flex,
  List,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";

const MenuItems = [
  {
    category: "company",
    items: [
      { text: "home", link: "https://homnics.com/" },
      { text: "about", link: "https://homnics.com/about.html " },
      { text: "hplan", link: "https://homnics.com/healthplan.html" },
    ],
  },
  {
    category: "patient",
    items: [{ text: "Login", link: "#" }],
  },
  {
    category: "hcare",
    items: [{ text: "Login", link: "#" }],
  },
  {
    category: "fororg",
    items: [
      { text: "Login", link: "#" },
      { text: "partner", link: "https://homnics.com/partners.html" },
    ],
  },
];

const ContactItems = [
  {
    text: "+2347064547071",
    isBlue: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-phone-call"
        width="21"
        height="21"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
        <path d="M15 7a2 2 0 0 1 2 2"></path>
        <path d="M15 3a6 6 0 0 1 6 6"></path>
      </svg>
    ),
  },
  {
    text: "chat",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-brand-whatsapp"
        width="21"
        height="21"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9"></path>
        <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1"></path>
      </svg>
    ),
  },
  {
    text: "Support@homnics.com",
    isBlue: true,
    link: "mailto:Support@homnics.com",
  },
];
export const Footer = () => {
  const { t } = useTranslation();

  const theme = useMantineTheme();
  const currentYear = new Date().getFullYear();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Stack mt={isSmallScreen ? "500px" : "20%"}>
      <Flex
        wrap="wrap"
        style={{
          marginLeft: !isSmallScreen ? "8%" : "",
          marginRight: !isSmallScreen ? "8%" : "",
          justifyContent: "space-around",
          flexDirection: isSmallScreen ? "column" : "row",
          alignContent: isSmallScreen ? "left" : "left",
        }}
      >
        {MenuItems.map((menuCategory, index) => (
          <List
            key={index}
            style={{
              margin: "10px",
              listStyleType: "none",
            }}
          >
            <List.Item>
              <h4>{t(menuCategory.category)}</h4>
            </List.Item>
            {menuCategory.items.map((menuItem, subIndex) => (
              <List.Item key={subIndex}>
                <Anchor
                  style={{
                    color: "black",
                  }}
                  href={menuItem.link}
                  target="_blank"
                >
                  {t(menuItem.text)}
                </Anchor>
              </List.Item>
            ))}
          </List>
        ))}
        <List
          style={{
            margin: "10px",
            listStyleType: "none",
          }}
        >
          <List.Item>
            <h4>{t("contact")}</h4>
          </List.Item>
          {ContactItems.map((contactItem, index) => (
            <List.Item key={index}>
              <Anchor
                style={{
                  color: contactItem.isBlue ? theme.colors.blue[5] : "black",
                  display: "flex",
                  alignItems: "center",
                }}
                href={contactItem.link}
                target="_blank"
              >
                {contactItem.icon}
                <span> {t(contactItem.text)}</span>
              </Anchor>
            </List.Item>
          ))}
        </List>
      </Flex>
      <Text
        style={{
          color: "#818182",
          textAlign: "center",
          paddingTop: "100px",
        }}
      >
        &copy; {currentYear} Homnics.{t("allright")}
      </Text>
    </Stack>
  );
};
