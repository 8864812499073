import { ComingSoonPage } from "./pages";
import { MantineProvider } from "@mantine/core";
import { themeOverride } from "./themes";
function App() {
  return (
    <MantineProvider theme={themeOverride} withGlobalStyles withNormalizeCSS>
      <ComingSoonPage />
    </MantineProvider>
  );
}

export default App;
