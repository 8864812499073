import { ReactComponent as HomnicsLogo } from "../assets/homnics-icon.svg";
import { Button, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

const allStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    backgroundColor: theme.white,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    boxShadow: `0 4px 2px -2px ${theme.colors.gray[1]}`,
    paddingLeft: parseInt(theme.spacing.xl) * 3,
    paddingRight: parseInt(theme.spacing.xl) * 3,
    zIndex: 5,
    [theme.fn.smallerThan("lg")]: {
      padding: "12px",
    },
  },
  logo: {
    width: "150px",
    height: "40px",
    marginLeft: "60px",

    [theme.fn.smallerThan("lg")]: {
      marginLeft: "5px",
      width: "80px",
      height: "40px",
    },
  },
  button: {
    fontSize: theme.fontSizes.small,
    marginRight: "20px",
    [theme.fn.smallerThan("lg")]: {
      marginRight: "5px",
    },
  },
  button1: {
    fontSize: theme.fontSizes.small,
    marginRight: "20px",
    color: "#006481",
    cursor: "pointer",
    [theme.fn.smallerThan("lg")]: {
      marginRight: "5px",
    },
  },
}));
interface NavbarProps {
  onChangeLanguage: (language: string) => void;
}

export const Navbar: React.FC<NavbarProps> = ({ onChangeLanguage }) => {
  const { t } = useTranslation();
  const { classes } = allStyles();

  return (
    <div className={classes.root}>
      <HomnicsLogo className={classes.logo} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button radius="md" className={classes.button}>
          {t("btn")}
        </Button>
        <h4 className={classes.button1} onClick={() => onChangeLanguage("en")}>
          Eng |
        </h4>
        <h4 className={classes.button1} onClick={() => onChangeLanguage("fr")}>
          Fra
        </h4>
      </div>
    </div>
  );
};
