import { useTranslation } from "react-i18next";
import { createStyles, Grid, Image, Stack, Text, Title } from "@mantine/core";
import Simplified from "../assets/Frame279835.png";
import Easy from "../assets/Frame279835(1).png";
import Connect from "../assets/Frame279835(2).png";
import Global from "../assets/Frame279835(3).png";

const applyStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    padding: "10px",
    marginTop: 75,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontWeight: 500,
    fontSize: 32,
    textAlign: "center",
    padding: 10,
  },
  titleElement: {
    fontSize: "23px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
    maxWidth: 200,

    [theme.fn.smallerThan("lg")]: {
      maxWidth: "80%",
    },
  },
  textElement: {
    fontSize: 13,
    textAlign: "center",
    width: "100%",
    maxWidth: 200,

    [theme.fn.smallerThan("lg")]: {
      maxWidth: "80%",
      fontSize: 16,
    },
  },
  grid: {
    width: "100%",
    maxWidth: 200,

    [theme.fn.smallerThan("lg")]: {
      maxWidth: "60%",
    },
  },
}));
const contentArray = [
  {
    imageSrc: Simplified,
    title: "title",
    text: "textcad",
  },
  {
    imageSrc: Easy,
    title: "titleone",
    text: "textcard",
  },
  {
    imageSrc: Connect,
    title: "titletwo",
    text: "texttwo",
  },
  {
    imageSrc: Global,
    title: "titlethree",
    text: "textthree",
  },
];

export const WhyUseHomnics = () => {
  const { t } = useTranslation();
  const { classes } = applyStyles();

  // The following line is not used in the current code
  // const { contentArray } = translationData;

  return (
    <div className={classes.root}>
      <div>
        <Title className={classes.title}>{t("whyuse")}</Title>
        <Grid gutter={2} align="center" gutterLg={50}>
          {contentArray.map(
            (content: {
              title: any;
              imageSrc: string | null | undefined;
              text: any | string | string[];
            }) => (
              <Grid.Col lg={3} md={6} sm={12} key={content.title} py={20}>
                <Stack align="center">
                  <Image
                    className={classes.grid}
                    mx="auto"
                    src={content.imageSrc}
                    // alt={`${content.title} image`}
                  ></Image>
                  <Title className={classes.titleElement} align="center">
                    {t(content.title)}
                  </Title>
                  <Text
                    c="background: #333333"
                    className={classes.textElement}
                    align="center"
                  >
                    {t(content.text)}
                  </Text>
                </Stack>
              </Grid.Col>
            )
          )}
        </Grid>
      </div>
    </div>
  );
};
