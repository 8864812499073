import { MantineThemeOverride } from "@mantine/core";

const fontFamily = "Red Hat Display, sans-serif";

export const themeOverride: MantineThemeOverride = {
  defaultRadius: 0,
  fontFamily,
  headings: {
    fontFamily,
  },
  fontSizes: {
    sm: "14",
    md: "16",
  },
  colors: {
    brand: [
      "#d9fdff",
      "#adf0ff",
      "#7de4ff",
      "#4dd9ff",
      "#26cefe",
      "#15b5e5",
      "#008cb3",
      "#006481",
      "#003d50",
      "#00161f",
    ],
    black: [
      "#d5d7e0",
      "#acaebf",
      "#8c8fa3",
      "#666980",
      "#4d4f66",
      "#34354a",
      "#2b2c3d",
      "#1d1e30",
      "#0c0d21",
      "#01010a",
    ],
    secondary: [
      "#e6fafe",
      "#bceefa",
      "#92e3f7",
      "#6cd8f4",
      "#53cef2",
      "#46b5d8",
      "#368ca7",
      "#266477",
      "#143c48",
      "#011419",
    ],
    tertiary: ["#B7D5C2", "#7B9986", "#6CD8F4"],
    dark: [
      "#d5d7e0",
      "#acaebf",
      "#8c8fa3",
      "#666980",
      "#4d4f66",
      "#34354a",
      "#2b2c3d",
      "#1d1e30",
      "#0c0d21",
      "#01010a",
    ],
  },
  primaryColor: "brand",
  primaryShade: 6,

  other: {
    letterSpacing: {
      sm: 0.5,
      md: 1,
      lg: 2,
    },
  },
};
