import { useTranslation } from "react-i18next";
import {
  Faqs,
  FeatureList,
  Footer,
  HeroSection,
  Navbar,
  SubscribeSection,
  WhyUseHomnics,
} from "../components";

export const ComingSoonPage = () => {
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  const handleChangeLanguage = (newLanguage: string) => {
    changeLanguage(newLanguage);
  };

  return (
    <div style={{ minWidth: 350 }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Navbar onChangeLanguage={handleChangeLanguage} />
      </div>

      <HeroSection />
      <FeatureList />
      <WhyUseHomnics />
      <Faqs />
      <SubscribeSection />
      <Footer />
    </div>
  );
};
