import { useEffect, useState } from "react";
import { Container, Text, Button, Image, Flex } from "@mantine/core";
import SearchDocs from "../assets/Search_docs_google-pixel4-clearlywhite-portrait.png";
import ConfirmAppoint from "../assets/confirm_booking_google-pixel4-justblack-portrait.png";
import { useTranslation } from "react-i18next";
// import { t,join } from "path";

export const SubscribeSection = () => {
  const { t } = useTranslation();

  // const { item, title } = translationData.FAQs;
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check for screen size
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        borderRadius: "15px",
        width: "100%",
        marginTop: isSmallScreen ? "80px" : "5%",
        maxWidth: "80%",
        backgroundImage: "linear-gradient( 90deg, #294B53 60%, #B7D5C2 100%)",
        marginBottom: isSmallScreen ? "450px" : "0",
        margin: isSmallScreen ? "auto" : "12%",
      }}
    >
      <Container style={{}}>
        <Flex
          align="left"
          style={{
            flexDirection: isSmallScreen ? "column" : "row",
            marginBottom: isSmallScreen ? "80px" : "0",
            justifyContent: isSmallScreen ? "center" : "space-between",
          }}
        >
          <div style={{ padding: "8%" }}>
            <Text
              lineClamp={6}
              style={{
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: isSmallScreen ? 14 : 20,

                paddingBottom: "10px",
              }}
            >
              {t("join")}
            </Text>
            <a href="#subscribe-link">
              <Button
                style={{
                  marginTop: "40px",
                  border: "none",
                  borderRadius: "5px",

                  background: "#06AED5",
                  color: "#FFFFFF",
                }}
              >
                {t("btn")}
              </Button>
            </a>
          </div>

          <div
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              marginLeft: isSmallScreen ? "auto" : "0",
            }}
          >
            <Image
              radius="md"
              src={SearchDocs}
              style={{
                marginTop: isSmallScreen ? "180px" : "0",
                position: "absolute",
                top: -180,
                left: 0,
                width: "60%",
                height: "auto",
                zIndex: 1,
              }}
            />
            <Image
              radius="md"
              src={ConfirmAppoint}
              style={{
                marginTop: isSmallScreen ? "180px" : "0",
                position: "absolute",
                top: -150,
                left: "35%",
                width: "60%",
                height: "auto",
                zIndex: 2,
              }}
            />
          </div>
        </Flex>
      </Container>
    </div>
  );
};
