import { Accordion, Title, createStyles } from "@mantine/core";
import { useTranslation } from "react-i18next";

const applyStyles = createStyles((theme) => ({
  root: {
    backgroundImage: "linear-gradient(#FFFFFF 15%, #06AED5 50%, #FFFFFF 100%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "left",
    padding: "20px",
    width: "100%",
    margin: "auto",
  },
  title: {
    fontSize: 20,
    [theme.fn.smallerThan("lg")]: {
      fontSize: 16,
    },
  },
  accordionItem: {
    marginTop: "10px",
    background: "RGBA(245, 255, 255, 0.7)",
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: "8px",
    padding: "10px",

    "&:hover": {
      background: "RGBA(245, 255, 255, 1)",
    },
  },
  accordiondescription: {
    fontSize: 16,

    [theme.fn.smallerThan("lg")]: {
      fontSize: 14,
    },
  },
}));

export const Faqs = () => {
  const { t, i18n } = useTranslation();
  const { classes } = applyStyles();

  // Load translation data dynamically based on the current language
  const translationData = require(`../locale/${i18n.language}.json`);

  const { item, title } = translationData.FAQs;

  const items = item.map((item: { value: any; description: any | string | string[]; }) => (
    <Accordion.Item
      value={item.value}
      key={item.value}
      className={classes.accordionItem}
    >
      <Accordion.Control className={classes.title}>
        {t(item.value)}
      </Accordion.Control>
      <Accordion.Panel className={classes.accordiondescription}>
        {t(item.description)}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className={classes.root}>
      <Title size={23} weight={500}>
        {t(title)}
      </Title>
      <Accordion maw={810} defaultValue={t("What is telemedicine?")}>
        {items}
      </Accordion>
    </div>
  );
};
