import { createStyles } from "@mantine/core";
import { Button, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const applyStyle = createStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: "490px",
    border: "solid 1px white",
    [theme.fn.smallerThan("lg")]: {
      marginBottom: "400px",
    },
  },
  gradientDiv: {
    position: "absolute",
    width: "100%",
    height: "510px",
    zIndex: 1,
    background:
      "linear-gradient(to bottom,  #06AED5 0%, rgba(255, 255, 255, 1) 100%)",
    [theme.fn.smallerThan("lg")]: {
      height: "410px",
    },
  },
  headingWrapper: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    marginTop: "190px",
    width: "65%",

    [theme.fn.smallerThan("lg")]: {
      width: "95%",
      top: "80%",
      marginTop: "90px",
    },
  },

  paragraphWrapper: {
    position: "absolute",
    top: "150%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    marginTop: "300px",
    width: "50%",

    [theme.fn.smallerThan("lg")]: {
      width: "90%",
      top: "80%",
      marginTop: "200px",
    },
  },

  text_1: {
    fontSize: "50px",
    textAlign: "center",
    textItems: "center",
    textColor: "#006481",

    [theme.fn.smallerThan("lg")]: {
      fontSize: "24px",
    },

    [theme.fn.smallerThan(885)]: {
      fontSize: "24px",
    },

    [theme.fn.smallerThan(376)]: {
      fontSize: "23px",
    },

    [theme.fn.smallerThan(361)]: {
      fontSize: "22px",
    },

    [theme.fn.smallerThan(321)]: {
      fontSize: "20px",
    },
  },
  text_2: {
    fontSize: "50px",
    textAlign: "center",
    textItems: "center",
    textColor: "#006481",
    marginTop: "-16px",

    [theme.fn.smallerThan("lg")]: {
      fontSize: "24px",
      marginTop: "-10px",
    },

    [theme.fn.smallerThan(769)]: {
      fontSize: "24px",
      marginTop: "-10px",
    },

    [theme.fn.smallerThan(376)]: {
      fontSize: "23px",
      marginTop: "-10px",
    },

    [theme.fn.smallerThan(361)]: {
      fontSize: "22px",
      marginTop: "-8px",
    },

    [theme.fn.smallerThan(321)]: {
      fontSize: "20px",
    },
  },
  paragraph: {
    textItems: "center",
    textAlign: "center",
    fontSize: "15.5px",
    marginTop: "10px",

    [theme.fn.smallerThan("lg")]: {
      fontSize: "14.5px",
      textAlign: "justify",
    },

    [theme.fn.smallerThan(376)]: {
      fontSize: "14px",
      textAlign: "justify",
    },
    [theme.fn.smallerThan(361)]: {
      fontSize: "14px",
      textAlign: "justify",
    },
  },

  inputStyles: {
    borderRadius: "12px",
  },

  groupedControls: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    marginTop: "460px",
    width: "32%",
    backgroundColor: "#E6E9EF",
    border: "#B7D5C2 1px solid",
    height: "54px",
    display: "flex",
    textItems: "center",
    borderRadius: "13px",
    padding: "8px",
    justifyContent: "space-between",

    [theme.fn.smallerThan("lg")]: {
      width: "94%",
      top: "70%",
      padding: "6px",
      marginTop: "385px",
    },
  },

  Username: {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
    marginTop: "390px",
    width: "32%",
    backgroundColor: "#E6E9EF",
    border: "#B7D5C2 1px solid",
    height: "54px",
    textItems: "center",
    borderRadius: "13px",
    padding: "8px",

    [theme.fn.smallerThan("lg")]: {
      width: "94%",
      top: "70%",
      padding: "6px",
      marginTop: "325px",
    },
  },
}));

export const HeroSection = () => {
  const { t } = useTranslation();
  const { classes } = applyStyle();
  const [email, setEmail] = useState("");
  const [name, setUsername] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleJoinWaitlist = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await fetch(
        "https://api.homnics.com/user-api/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email }),
        }
      );

      if (response.ok) {
        alert("You've subscribed to the waiting list Successfully");
        setUsername("");
        setEmail("");
      } else {
        const data = await response.json();
        if (data.errors) {
          const errorMessages = Object.values(data.errors).join("\n");
          alert(`Validation Errors:\n${errorMessages}`);
        } else {
          alert("Error: " + data.title);
        }
      }
    } catch (error: any) {
      alert("Error: " + error.message);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.gradientDiv}></div>
      <div className={classes.headingWrapper}>
        <Text fw={700} c="#1F4149" className={classes.text_1}>
          {t("yourhealth")}
        </Text>
        <Text fw={700} c="#1F4149" className={classes.text_2}>
          {t("goodbye")}
        </Text>
      </div>

      <div className={classes.paragraphWrapper}>
        <Text c="#1F4149" className={classes.paragraph}>
          {t("ourtele")}
        </Text>
      </div>
      <form onSubmit={handleJoinWaitlist} id="subscribe-link">
        <div className={classes.Username}>
          <TextInput
            value={name}
            required
            onChange={handleUsernameChange}
            radius="md"
            variant="unstyled"
            className={classes.inputStyles}
            placeholder={t("typname")}
            size="md"
          />
        </div>
        <div className={classes.groupedControls}>
          <TextInput
            value={email}
            required
            onChange={handleEmailChange}
            radius="md"
            variant="unstyled"
            className={classes.inputStyles}
            placeholder={t("typemail")}
            size="md"
          />
          <Button
            type="submit"
            radius="md"
            color="#06AED5"
            size="sm"
            style={{ display: "block" }}
          >
            {t("btn")}
          </Button>
        </div>
      </form>
    </div>
  );
};
