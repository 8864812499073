import { createStyles, Title, Text, Image, Group, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import basketBall from "../assets/Frame 8.png";
import pigyBank from "../assets/Frame 7.png";

const applyStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    padding: parseInt(theme.fontSizes.xl) * 3.5,
    marginTop: theme.fontSizes.xl,
    background: "radial-gradient(closest-side, #E6E9EF, #ffffff, #ffffff)",
    height: "100%",
  },
  spanWrapper: {
    color: "#0090B7",
  },
  titleHeader: {
    fontSize: "32px",
    fontWeight: 500,
    marginBottom: "10px",
    [theme.fn.smallerThan("lg")]: {
      textAlign: "center",
    },
  },
  paragraph: {
    width: "340px",
    fontSize: "15.5px",

    [theme.fn.smallerThan("lg")]: {
      textAlign: "justify",
      width: "100%",
      padding: "2px",
    },
  },

  image: {
    height: "100%",
    maxHeight: 200,
    width: "100%",
    maxWidth: 350,

    [theme.fn.smallerThan("lg")]: {
      height: "100%",
      width: "100%",
    },
    [theme.fn.smallerThan(376)]: {
      height: "250px",
      width: "250px",
    },
  },
  secondgroup: {
    [theme.fn.smallerThan("lg")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
}));

export const FeatureList = () => {
  const { t } = useTranslation();
  const { classes } = applyStyles();

  return (
    <div className={classes.root}>
      <Stack mx="auto" spacing="200px" mt={30} px={10}>
        <Group position="center" spacing="xl">
          <Stack>
            {/* heading */}
            <Title className={classes.titleHeader} c="#01232B">
              {t("right")}
              <span className={classes.spanWrapper}>{t("span")}</span>
            </Title>
            {/* base text */}
            <Text c="#01232B" className={classes.paragraph}>
              {t("text")}
            </Text>
          </Stack>

          <Image
            className={classes.image}
            src={basketBall}
            alt="Random image"
          />
        </Group>
        <Group position="center" spacing={100} className={classes.secondgroup}>
          <Image
            className={classes.image}
            src={pigyBank}
            alt="Piggy bank image"
          />
          <Stack>
            {/* heading */}
            <Title className={classes.titleHeader} c="#01232B">
              <span className={classes.spanWrapper}>{t("affordable")}</span>{" "}
              {t("healtcare")}
            </Title>
            {/* base text */}
            <Text c="#01232B" className={classes.paragraph}>
              {t("convienttext")}
            </Text>
          </Stack>
        </Group>
      </Stack>
    </div>
  );
};
